import type { AppPlugin, KeyValue } from '@grafana/data';
import type { GrafanaBootConfig } from '@grafana/runtime';

export const PluginId = {
  OnCall: 'grafana-oncall-app',
  Irm: 'grafana-irm-app',
  Incident: 'grafana-incident-app',
} as const;
export type PluginId = (typeof PluginId)[keyof typeof PluginId];

export function getIsIrmPluginPresent(): boolean {
  return PluginId.Irm in (window?.grafanaBootData?.settings as GrafanaBootConfig).apps;
}

export function isCurrentPlugin(pluginId: PluginId): boolean {
  return getPluginId(null as unknown as PluginId) === pluginId;
}

export function getPluginId(defaultValue: PluginId = PluginId.Irm): PluginId {
  try {
    return (process.env.PLUGIN_ID ?? defaultValue) as PluginId;
  } catch (error) {
    return defaultValue;
  }
}

export function addExtensionComponent<T extends KeyValue = KeyValue>(
  plugin: AppPlugin<T>,
  config: Parameters<typeof plugin.addComponent>[0]
): void {
  if (plugin.addComponent != null) {
    // v11
    plugin.addComponent(config);
  } else {
    // v10
    const { targets, ...reusedConfig } = config;
    const extensionPointId = typeof targets === 'string' ? targets : targets[0];
    if (extensionPointId != null) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      plugin.configureExtensionComponent({
        ...reusedConfig,
        extensionPointId,
      });
    }
  }
}

export function addExtensionLink<T extends KeyValue = KeyValue>(
  plugin: AppPlugin<T>,
  config: Parameters<typeof plugin.addLink>[0]
): void {
  if (plugin.addLink != null) {
    // v11
    plugin.addLink(config);
  } else {
    // v10
    const { targets, ...reusedConfig } = config;
    const extensionPointId = typeof targets === 'string' ? targets : targets[0];
    if (extensionPointId != null) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      plugin.configureExtensionLink({
        ...reusedConfig,
        extensionPointId,
      });
    }
  }
}

export function exposeComponent<T extends KeyValue = KeyValue>(
  plugin: AppPlugin<T>,
  config: Parameters<typeof plugin.exposeComponent>[0]
): void {
  if (plugin.exposeComponent != null) {
    // v11
    plugin.exposeComponent(config);
  } // there's no v10 equivalent
}
